import { isArray, isObject } from "lodash";
import moment from "moment";

export const formatGames = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.description}`,
      value: data._id,
      date: data.date && moment(data.date),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.description}`,
      date: data.date && moment(data.date),
    };
  }
};
export const formatUsers = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name} ${data.surname}`,
      value: data._id
    }));
  }
  if (isObject(data)) {
    return {
      value: data._id,
      label: `${data.name} ${data.surname}`,
      ...data,
    };
  }
};

export const formatAlerts = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.title}`,
      value: data._id,
      ...data,
    }));
  }
  if (isObject(data)) {
    return {
      value: data._id,
      label: `${data.title}`,
      ...data,
    };
  }
};

export const formatSeries = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      label: `${data.name}`,
      value: data._id,
      ...data,
      end: data.end ? moment(data.end) : undefined,
      seasonEnd: data.seasonEnd ? moment(data.seasonEnd) : undefined,
      visualizationDate: data.visualizationDate ? moment(data.visualizationDate) : undefined,
      notify: data.notify ? moment(data.notify) : undefined
    }));
  }
  if (isObject(data)) {
    return {
      value: data._id,
      label: `${data.name}`,
      ...data,
      end: data.end ? moment(data.end) : undefined,
      seasonEnd: data.seasonEnd ? moment(data.seasonEnd) : undefined,
      visualizationDate: data.visualizationDate ? moment(data.visualizationDate) : undefined,
      notify: data.notify ? moment(data.notify) : undefined
    };
  }
};

export const formatSuggestions = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      value: data._id,
      end: data.end && moment(data.end),
      start: data.start && moment(data.start),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name}`,
      end: data.end && moment(data.end),
      start: data.start && moment(data.start),
    };
  }
};