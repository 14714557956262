import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_SERIE,
    DELETE_SERIE,
    SERIE_FORM_TOOGLE_LOADING,
    SET_SERIE_LIST,
    SHOW_NOTIFICATION,
    UPDATE_SERIE,
} from "../constants";
import { formatSeries } from "./settingsActionsUtils";

/* SERIE LIST */
export const fetchSeries = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.series.list)
            .then((response) => {
                const series = formatSeries(response.data);
                dispatch({ type: SET_SERIE_LIST, payload: keyBy(series, "_id") });
                response.data = keyBy(series, "_id")
                return response;
            })
            .catch((err) => err);
        return response;
    };
};

// SERIES
export const createSerie = (newSerie) => {
    return async (dispatch) => {
        dispatch({ type: SERIE_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.series.create, newSerie)
            .then((response) => {
                const serie = formatSeries(response.data);
                dispatch({ type: ADD_NEW_SERIE, payload: serie });
                dispatch({ type: SERIE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Serie creada con éxito.",
                    },
                });
                return response.status;
            })
            .catch((err) => {
                dispatch({ type: SERIE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateSerie = (updatedSerie) => {
    return async (dispatch) => {
        dispatch({ type: SERIE_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.series.edit}${updatedSerie._id}`, updatedSerie)
            .then((response) => {
                const serie = formatSeries(response.data);
                dispatch({ type: UPDATE_SERIE, payload: serie });
                dispatch({ type: SERIE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Serie actualizada.",
                    },
                });
                return response.status;
            })
            .catch((err) => {
                dispatch({ type: SERIE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteSeries = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: SERIE_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.series.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_SERIE, payload: Ids });
                dispatch({ type: SERIE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Series eliminadas. ",
                    },
                });
                return response.status;
            })
            .catch((err) => {
                dispatch({ type: SERIE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
