export const API = {
	games: {
    create: `/game`,
    edit: `/game/`,
    delete: `/game/`,
    list: `/game`,
  },
  auth: {
    login: `/user/login`,
    check2FA: `/user/check2FA`,
  },
  users: {
    profile: `/user/profile`,
    create: `/user/register/`,
    edit: `/user/`,
    delete: `/user/`,
    list: `/user`,
  },
  alerts: {
    create: `/alert`,
    edit: `/alert/`,
    delete: `/alert/`,
    list: `/alert`,
  },
  series: {
    create: `/serie`,
    edit: `/serie/`,
    delete: `/serie/`,
    list: `/serie`,
  },
  suggestions: {
    create: `/suggestion`,
    edit: `/suggestion/`,
    delete: `/suggestion/`,
    list: `/suggestion`,
  },
};
