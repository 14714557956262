import { combineReducers } from "redux";
import {
  authorization,
  profile,
  currentRoute,
  notifications,
  app,
  usersForm,
  users,
  alerts,
  alertsForm,
  currentTheme,
  series,
  seriesForm,
  suggestions,
  suggestionsForm,
  games,
  gamesForm,
} from "./Reducers";

export default combineReducers({
  games,
  gamesForm,
  app,
  authorization,
  currentRoute,
  profile,
  notifications,
  usersForm,
  users,
  alerts,
  alertsForm,
  series,
  seriesForm,
  suggestions,
  suggestionsForm,
  currentTheme,
});
